@font-face {
  font-family: AleckSansRegular;
  src: url(https://d1yfqobqq7euu8.cloudfront.net/fonts/WarnerMedia_WEB/ATTAleckSans-Regular.woff);
}
@font-face {
  font-family: AleckSansItalic;
  src: url(https://d1yfqobqq7euu8.cloudfront.net/fonts/WarnerMedia_WEB/ATTAleckSans-Italic.woff);
}
@font-face {
  font-family: AleckSansThin;
  src: url(https://d1yfqobqq7euu8.cloudfront.net/fonts/WarnerMedia_WEB/ATTAleckSans-Thin.woff);
}
@font-face {
  font-family: AleckSansBold;
  src: url(https://d1yfqobqq7euu8.cloudfront.net/fonts/WarnerMedia_WEB/ATTAleckSans-Bold.woff);
}
@font-face {
  font-family: AleckSansBlack;
  src: url(ttps://d1yfqobqq7euu8.cloudfront.net/fonts/WarnerMedia_WEB/ATTAleckSans-Black.woff);
}

$orange:#e5bc73;
$darkgrey:#1e1e1e;

body {
  margin: 0;
  font-family: AleckSansRegular, 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #eaeaea !important;
  //color: white !important;
}
.close>span{
  color: black !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.side-nav{
  display: block;
  width:100%;
  padding:15px 0;
  text-align: center;

    color: white !important;

  &.active{
    background-color: #1e1e1e;
    border-left:4px solid $orange;
  }
}

.bg-lighter{
  background-color: white !important;
}
.mb-dark{
  border-bottom: 1px solid #cfcfcf;
}

table{

padding:10px;
margin-bottom: 20px;
width:100%;
  th{
    background-color: black;
    color: white;
    padding:10px;
  }
}

.btn-primary{
  background-color: $orange !important;
  color: black !important;
  border: 1px solid white !important;
}

.modal-content {
  color: black !important;
}

.navbar-brand{
  background-image: url('Logo.png');
  background-size: 100%;
  background-repeat: no-repeat;
  width: 200px;
  height:50px;
  background-position: center;
}
.sc-fzpans{
  color: black !important;
}

.status-NEW{
  background-color: lighten(#acc41e,10);
  padding:5px 10px;
  border-radius: 100px;
  border:1px solid #acc41e;
  color: black;
  min-width: 60px;
  text-align: center;
}

.status-USED{
  background-color: lighten($orange,10);
  padding:5px 10px;
  border-radius: 100px;
  border:1px solid $orange;
  color: black;
  min-width: 60px;
  text-align: center;
}

.rdt_TableHeader{
  display: none !important;
}
.copy{
  border:1px solid lightgray;
  display: inherit;
  cursor: pointer;
  span{
    padding:5px;
    //margin:5px;
  }

  &:after{
    content:"COPY";
    background-color: lightgray;
    word-wrap: unset;
    //padding:5px;
    line-height: 30px;
    padding: 0 10px;
    word-break: unset;
word-wrap: unset;
overflow-wrap: unset;
-webkit-hyphens: unset;
-moz-hyphens: unset;
-ms-hyphens: unset;
hyphens: unset;
  }
}
.App{
  min-height:calc(100vh - 110px);
}
.loader{
	z-index: 200;
	position: fixed;
	width: 100vw;
	height:100vh;
	padding:0;
	margin:0;
	background: $darkgrey;
	top:0;
	left:0;
	.spinner{
		position:absolute;
		width:100%;
		color: $orange;
		top:calc(50vh - 1rem);
	}
	.message{
		position: absolute;
		color: red;
    	top: 3rem;
	}
}

.dev_nav{
  padding:10px;
  padding-right:3em;
  background-color: red;
  text-align: right;
  font-weight: bold;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
	background-repeat: no-repeat, repeat;
	background-position: right 0.7em top 50%, 0 0;
	background-size: 0.65em auto, 100%;
}
.stg_nav{
  padding:10px;
  padding-right:3em;
  background-color: orange;
  text-align: right;
  font-weight: bold;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
	background-repeat: no-repeat, repeat;
	background-position: right 0.7em top 50%, 0 0;
	background-size: 0.65em auto, 100%;
}
.prd_nav{
  padding:10px;
  padding-right:3em;
  background-color: green;
  text-align: right;
  font-weight: bold;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
	background-repeat: no-repeat, repeat;
	background-position: right 0.7em top 50%, 0 0;
	background-size: 0.65em auto, 100%;
}

.modal-header{
	border:none !important;
  text-align: center !important;
  color: white;
	h2{
    width:100%;
    color: white;
	}
}
.modal{
	background-color: $darkgrey;
	top:64px;
}
.modal-dialog, .modal-content{
		background-color: $darkgrey !important;
		border:none !important;
  }
  
  .close{
    color: white !important;
  }
  .env-btn{
    width: 100%;
    display:block;
    margin-bottom: 1em;;
  }